import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { graphql, useStaticQuery } from "gatsby"
import TransitionLink from 'gatsby-plugin-transition-link'
import React, { useRef } from 'react'
import { InView } from 'react-intersection-observer'
import Slick from "react-slick"
import "slick-carousel/slick/slick.css"
import Formularz from '../../components/formularz/formularz'
import Identyfikacja from '../../components/projekty/beefit/1'
import Print from '../../components/projekty/beefit/2'
import DigitalDesign from '../../components/projekty/beefit/3'
import Intro from '../../components/projekty/comp/projekt_intro'
import Inne from '../../components/projekty/inne'
import Ocena from '../../components/projekty/OcenaProjektu'
import Seo from '../../components/seo.js'
import Slider from '../../components/slider/slider'
import back from '../../images/ikony/goback.svg'
import oko from '../../images/ikony/oko.svg'
import paluch from '../../images/ikony/paluch.svg'
import strzalka from '../../images/ikony/strzalka.svg'
import okladka2 from '../../images/projekty/beefit/okladka2@2x.jpg'
import okladka from '../../images/projekty/beefit/okladka@2x.jpg'
import style from './projekt.module.css'








let title = "BEEFIT"

export default function Betard() {
    // polyfill();
    const slider = useRef(null)
    const wrapper = useRef(null)

    const r1 = useRef(null)
    const r2 = useRef(null)
    const r3 = useRef(null)

    const data = useStaticQuery(graphql`
            query {
                grid: file(relativePath: { eq: "projekty/betard/1/grid.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 75) {
                    ...GatsbyImageSharpFluid
                    }
                }
                }

                
            }
            `)
 


    useScrollPosition(
        ({ prevPos, currPos }) => {
        console.log(currPos.x)
        console.log(currPos.y)
        },
        [],
        [wrapper],
        false,
        300,
    )

        


    let settingss = {
            dots: false,
            adaptiveHeight: true,
            infinite: false,
            arrows: false,
            centerMode: false,
            speed: 300,
            slidesToShow: 3,
            variableWidth: true, 
    }    



    return (    
        <>
        <div style={{
            height: "calc(100vh - 60px)",
            overflow: "y-scroll",
        }}
        ref={wrapper}>
            <div id="projekt-bread" className="projekt-aktywny projekt-header">
                <div className="lista-item">
                    <TransitionLink style={{
                        left: '0',
                        top: '0',
                        position:'absolute',
                    }} to="/projekty">
                        <img className="projekt-header-cofnij" src={back} />
                    </TransitionLink>
                    <h3>{title}</h3>
                    <div className="projekt-zajawka">
                        <img src={oko} />
                        <span className="przycisk">2020</span>
                        <div className="projekt-zajawka-kategorie">
                            <span className="przycisk">design</span>
                            <span className="przycisk">strategia</span>
                    
                        </div>
                        
                        <p>Bee Fit to polska marka na rynku suplementacji dla sportowców. My odpowiadaliśmy za opracowanie systemu identyfikacji, projekt opakowań i stworzenie materiałów do mediów społecznościowych.</p>
                        <span 
                        style={{whiteSpace: 'nowrap', display: 'flex', alignItems: 'center'}}
                        className="przycisk"><img src={paluch} style={{marginRight: '10px'}}/>ZOBACZ PROJEKT</span>
                    </div>

                    { <div className="bread-dodatkowe slider-rozdzialy">
                    <div className="rozdzialy-maska"></div>
                    <Slick  ref={slider} className="rozdzial-slider" {...settingss}>
                    
                        <span onClick={
                            () => { r1.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(0);}}
                            className="przycisk">01. IDENTYFIKACJA WIZUALNA</span>
                        <span onClick={
                            () => { r2.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(1);}}
                        
                        
                        className="przycisk">02. PRINT</span>  
                        <span onClick={
                            () => { r3.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(2);}} 
                       className="przycisk">03. DIGITAL DESIGN</span>  
                        
                        
                    </Slick>
                    <div className="rozdzialy-maska"></div>
                    </div>}

                </div>

            </div>


            <Seo title={title} description="Bee Fit to nowa polska marka na rynku suplementacji dla sportowców. W Odrze odpowiadaliśmy za opracowanie systemu identyfikacji, projekt opakowań i stworzenie podstawowych materiałów do mediów społecznościowych." image={okladka} />
            <div className="podstrona-wrapper projekt-padding">

            <img style={{width: '100%'}} src={okladka} />

            <Intro tytul="Bee Fit - nowoczesne suplementy dla sportowców" tekst="Bee Fit to nowa polska marka na rynku suplementacji dla sportowców. W Odrze odpowiadaliśmy za opracowanie systemu identyfikacji, projekt opakowań i stworzenie podstawowych materiałów do mediów społecznościowych." 
            tekst1="Założeniem marki jest stały rozwój oferty produktowej - naszym zadaniem było stworzenie elastycznego systemu, który pozwoli na wdrażanie nowych rozwiązań przy zachowaniu spójności marki i wzajemną identyfikacje produktów." 
            tekst2="Bee Fit to nie produkty dla „pakerów”. Odbiorcami marki są głównie sportowcy amatorzy - m.in. biegacze, entuzjaści kolarstwa czy crossfitu. Oferta marki dostępna będzie głównie za pośrednictwem sklepów online." />

            <img style={{width: '100%'}} src={okladka2} />








            <div className={style.architektura}>
                <h3>ARCHITEKTURA PROJEKTU</h3>
                <div>

               
                        <div onClick={() => r1.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   } data-rozdzial={1}>
                            <span className="przycisk">
                                01. IDENTYFIKACJA WIZUALNA
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>

                        <div onClick={() => r2.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   }  data-rozdzial={2}>
                            <span className="przycisk">
                                02. PRINT
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>

                        <div onClick={() => r3.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   }  data-rozdzial={3}>
                            <span className="przycisk">
                                03. DIGITAL DESIGN
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>
                        
                       


           
                </div>
            </div>
            </div>


            <InView  as="div" threshold="0.35" onChange={(inView, entry) => slider.current.slickGoTo('0')}>
            <div ref={r1}><Identyfikacja  /></div>
            </InView>

            <InView as="div" threshold="0.35" onChange={(inView, entry) => slider.current.slickGoTo('1')}>
            <div ref={r2}><Print /></div>
            </InView>
            <InView as="div" threshold="0.35" onChange={(inView, entry) => slider.current.slickGoTo('2')}>
            <div ref={r3}><DigitalDesign /></div>
            </InView>
          
        

            <div className="podstrona-wrapper">
            <Ocena tekst='"Jednym z głównych atutów Firmy Odra Studio jest łączenie w sobie dopasowania się do potrzeb rynkowych jednoczenie kreując wizję klienta. Młody zespół zdolnych ludzi potrafi działać pod presją czasu absolutnie nie tracąc przy tym profesjonalnego efektu końcowego. Są otwarci na pomysły, które potrafią w kreatywny sposób rozwinąć tak aby osiągnąć zamierzony cel marketingowy. Dzięki swojej znajomości rynku są również skorzy do negowania działań, które ich zdaniem są dla klienta niekorzystne lub nie przyniosą wymiernych efektów. W zamian proponując nam rozwiązania, które okazały się sukcesem."' firma="Reprezentant Polski na żużlu" imie="Maciej Janowski" />

            <Slider  uslugi={true} />
            </div>

            <Inne omin="Beefit" />


            


            <Formularz />
            </div>
      
         
        </> 
    )
}