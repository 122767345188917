import React, { useState } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Rozdzial from '../comp/rozdzial'
import TytulPunkty from '../comp/tytul_punkty'
import Komp_zdjecie from '../comp/komp_2kol_tekst_foto'
import Komp_zdjecie2 from '../comp/kol_tekst'
import Img from "gatsby-image"
import Punkt from '../comp/punkt_flex'



export default function Research() {
    const data = useStaticQuery(graphql`
            query {
               
                wideo: file(relativePath: { eq: "projekty/beefit/1/okladka@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( quality: 75) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                    },


                etymologia: file(relativePath: { eq: "projekty/beefit/1/etymologia@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                    },
                logo: file(relativePath: { eq: "projekty/beefit/1/logo@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                    }      
                
                 ruch: file(relativePath: { eq: "projekty/beefit/1/ruch@2x.jpg" },
                 sourceInstanceName: {
                     eq: "images"
                   }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                    },  
                    komp1: file(relativePath: { eq: "projekty/beefit/1/komp1@2x.jpg" },
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                        childImageSharp {
                            fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                        }
                        }, 
                        
            komp2: file(relativePath: { eq: "projekty/beefit/1/komp2@2x.jpg" },
            sourceInstanceName: {
                eq: "images"
              }) {
                childImageSharp {
                    fluid( maxWidth: 2000, quality: 100) {
                    ...GatsbyImageSharpFluid
                    }
                }
                }, 

                pattern: file(relativePath: { eq: "projekty/beefit/1/pattern@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                    },  
                    komp3: file(relativePath: { eq: "projekty/beefit/1/komp3@2x.jpg" },
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                        childImageSharp {
                            fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                        }
                        },  

                        plakaty: file(relativePath: { eq: "projekty/beefit/1/plakaty@2x.jpg" },
                        sourceInstanceName: {
                            eq: "images"
                          }) {
                            childImageSharp {
                                fluid( maxWidth: 2000, quality: 100) {
                                ...GatsbyImageSharpFluid
                                }
                            }
                            },         
                            
                            brandbook: file(relativePath: { eq: "projekty/beefit/1/brandbook@2x.jpg" },
                            sourceInstanceName: {
                                eq: "images"
                              }) {
                                childImageSharp {
                                    fluid( maxWidth: 2000, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                    }
                                }
                                }, 
            }
            `)
    return(
        <>

        <Rozdzial tytul="IDENTYFIKACJA WIZUALNA" tekst="Ideą sformułowaną przez klienta była budowa marki wokół symbolu pszczoły i nazwy zawierającej przedrostek „Bee”. Popularny motyw wymagał od nas wyjścia poza nawias i znalezienia elementów, które zbudują „pszczelość” projektu, nie powielając schematów w postaci figuratywnego wizerunku pszczoły i patternów opartych na symbolice plastrów miodu. Nasza koncepcja zakładała oparcie wizerunku marki na ruchu i dynamice lotu owada oraz charakterystycznej żółto-czarnej kolorystyce." nr="01." img={data.wideo.childImageSharp.fluid}>
        <TytulPunkty tekst="Chcieliśmy uniknąć  najprostszych skojarzeń - skoro Bee, to pszczoła i miód. Naszą  inspiracją był ruch owada, który bezpośrednio nawiązywał do korzyści, których dostarcza produkt - szybkości i zwinności." />
        <Img fluid={data.etymologia.childImageSharp.fluid} />
        <TytulPunkty tekst="Głównym motywem projektu jest wykorzystanie charakterystycznej typografii i jej deformacja - ruch wynikający z lotu pszczoły. " />
        <Img fluid={data.logo.childImageSharp.fluid} />
        <Punkt tytul="Pełna wersja znaku" tekst="Znak zawiera główne charakterystyczne elementy systemu wizualnego - dominującą typografię, metaforyczny symbol pszczoły i ślad jej ruchu." />
        <Img fluid={data.ruch.childImageSharp.fluid} />
        <Punkt tytul="Sygnet i brand hero" tekst="Symbol pszczoły umieszczony w znaku został rozwinięty i odgrywa wiodącą rolę w komunikacji marki. Jego celem jest wzmocnienie energii przekazu, zasygnalizowanie roli produktu w aktywności sportowej, pozytywnego wpływu na organizm i urozmaicenie materiałów komunikacyjnych." invert={true}/>
        <Komp_zdjecie 
        img1={data.komp2.childImageSharp.fluid} 
        img2={data.komp1.childImageSharp.fluid} 
        tytul1="KOLORYSTYKA"
        tekst1="Żółto - czarna kolorystyka odnosi się bezpośrednio do skojarzeń z pszczołą.  Paleta podstawowa została rozwinięta tak, by umożliwić rozwój architektury produktów przy jednoczesnym zachowaniu spójności marki. Niezależnie od produktów dodatkowe kolory są uzupełnieniem głównych barw marki."
        tytul2="Typografia"
        tekst2="Charakterystyczny krój o mocnej budowie został dobrany celowo, by współgrać z motywem ruchu. Zbyt szczupła typografia mogłaby tracić na czytelności w sytuacji jej deformacji. Anybody sprawdził się idealnie przy tworzeniu kolejnych materiałów komunikacyjnych i opakowań produktowych, których wielkość wymagała czytelnych rozwiązań."
         />
        <Punkt tytul="Pattern typograficzny" tekst="Istotnym elementem systemu identyfikacji marki jest pattern typograficzny, który pojawia się zarówno w materiałach komunikacyjnych jak i na opakowaniach produktów. Cechą charakterystyczną jest jego dynamika i deformacje oddające ruch, wynikający z lotu pszczoły." />
        <Img fluid={data.pattern.childImageSharp.fluid} /> 

        <Komp_zdjecie2
        invert={true}
        img={data.komp3.childImageSharp.fluid} tytul="Graficzne elementy systemu" tekst="Dodatkowe elementy graficzne wykorzystywane są w celu przekazania istotnych informacji m.in. w materiałach komunikacyjnych i na opakowaniach produktów. Geometryczne kształty nie kolidują z innymi elementami systemu a charakterystyczny sześciobok nawiązuje do plastra miodu. Poznaj zastosowania elementów w sekcji dotyczącej materiałów drukowanych (Print)."/> 

        <Img fluid={data.plakaty.childImageSharp.fluid} /> 

        <Komp_zdjecie2
        img={data.brandbook.childImageSharp.fluid} tytul="Brandbook" tekst="Instrukcja użycia marki została opisana w formie brandbooka. Dzięki niemu pracownicy, partnerzy, czy agencje obsługujące mogą przyspieszyć proces działań i upewnić się, że marka rozwija się w jednym, ustalonym kierunku, wysyłając oczekiwane i spójne komunikaty wobec swoich interesariuszy."/> 

        </Rozdzial>
        
        </>
    )
}