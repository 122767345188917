import React, { useState } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Rozdzial from '../comp/rozdzial'
import TytulPunkty from '../comp/tytul_punkty'
import Slider from '../comp/slider_foto'
import Komp_zdjecie from '../comp/kol_tekst'
import Komp_kol from '../comp/komp_2kol_tekst_foto'
import Img from "gatsby-image"
import wideo from '../../../images/projekty/betard/3/okladka.mp4'

import system from '../../../images/projekty/betard/3/system.mp4'


export default function Identyfikacja() {
    const data = useStaticQuery(graphql`
            query {
                okladka: file(relativePath: { eq: "projekty/beefit/3/okladka@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                    },

                    full1: file(relativePath: { eq: "projekty/beefit/3/full1.jpg" },
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                        childImageSharp {
                            fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                        }
                        },

                        full2: file(relativePath: { eq: "projekty/beefit/3/full2.jpg" },
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                        childImageSharp {
                            fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                        }
                        },

                        full3: file(relativePath: { eq: "projekty/beefit/3/full3.jpg" },
                    sourceInstanceName: {
                        eq: "images"
                      }) {
                        childImageSharp {
                            fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                        }
                        },

                        komp1: file(relativePath: { eq: "projekty/beefit/3/komp1.jpg" },
                        sourceInstanceName: {
                            eq: "images"
                          }) {
                            childImageSharp {
                                fluid(maxWidth: 2000, quality: 100) {
                                ...GatsbyImageSharpFluid
                                }
                            }
                            },

            }
            `)

    


   
    
    
    return(
        <>

        <Rozdzial tytul="DIGITAL DESIGN" invert={false} tekst="Po opisaniu założeń marki, rozpoczęliśmy etap odświeżenia identyfikacji wizualnej. Punktem wyjścia był funkcjonujący dotychczas znak, w którym dokonano nieznacznych korekt optycznych. Na kanwie kwadratu, na podstawie którego stworzony został sygnet, wyprowadziliśmy siatkę - element organiczny dla projektowania i budownictwa, która będąc widoczną, stanowiła budulec identyfikacji. Jej skalowanie, w połączeniu z określonym zastosowaniem typografii, fotografii, ilustracji technicznej i koloru Betard Blue, stanowią dziś o wyjątkowym, charakterystycznym wizerunku marki Betard. ." nr="03." img={data.okladka.childImageSharp.fluid}>

            <TytulPunkty tekst="W ramach projektu stworzyliśmy materiały mediowe, które pomogą  marce w podstawowej obecności, m.in. w social mediach. Zostały one dostosowane do działań własnych oraz planowanych współprac z influencerami." />

            <div>
                <Img fluid={data.full1.childImageSharp.fluid} />
            </div>

            <TytulPunkty tekst="Prezentacja sprzedażowa w wersji cyfrowej zawiera wszystkie najważniejsze informacje o marce, produktach Bee Fit i oddaje osobowość  projektu." />

            <div>
                <Img fluid={data.full2.childImageSharp.fluid} />
            </div>

            <div>
                <Img fluid={data.full3.childImageSharp.fluid} />
            </div>

            <Komp_zdjecie img={data.komp1.childImageSharp.fluid} tytul="Oferta produktowa" tekst="Oferta produktowa przystosowana do ekspozycji cyfrowej zawiera najważniejsze informacje dla sprzedawców oraz elementy materiałów, które posłużą dystrybutorom do ekspozycji marki na własnych platformach e-commerce." /> 
           
     
        

        
        
        </Rozdzial>
        
        </>
    )
}