import React, { useState } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Rozdzial from '../comp/rozdzial'
import TytulPunkty from '../comp/tytul_punkty'
import Slider from '../comp/slider_foto'


import Img from "gatsby-image"

import KolImg from '../comp/kol_tekst'
import anim from '../../../images/projekty/betard/2/mocny_partner.mp4'

import Info from '../comp/punkt_flex'

import Komp_zdjecie from '../comp/komp_2kol_tekst_foto'
import Komp_zdjecie2 from '../comp/kol_tekst'

import Punkt from '../comp/punkt_flex'







export default function Strategia() {

    const data = useStaticQuery(graphql`
            query {
                okladka: file(relativePath: { eq: "projekty/beefit/2/okladka@2x.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                childImageSharp {
                    fluid(maxWidth: 2000, quality: 100) {
                    ...GatsbyImageSharpFluid
                    }
                }
                },


                full1: file(relativePath: { eq: "projekty/beefit/2/full1.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                childImageSharp {
                    fluid(maxWidth: 2000, quality: 100) {
                    ...GatsbyImageSharpFluid
                    }
                }
                },

                full2: file(relativePath: { eq: "projekty/beefit/2/full2.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                childImageSharp {
                    fluid(maxWidth: 2000, quality: 100) {
                    ...GatsbyImageSharpFluid
                    }
                }
                },

                full3: file(relativePath: { eq: "projekty/beefit/2/full3.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                childImageSharp {
                    fluid(maxWidth: 2000, quality: 100) {
                    ...GatsbyImageSharpFluid
                    }
                }
                },

                full4: file(relativePath: { eq: "projekty/beefit/2/full4.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                childImageSharp {
                    fluid(maxWidth: 2000, quality: 100) {
                    ...GatsbyImageSharpFluid
                    }
                }
                },

                komp1: file(relativePath: { eq: "projekty/beefit/2/komp1.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                childImageSharp {
                    fluid(maxWidth: 2000, quality: 100) {
                    ...GatsbyImageSharpFluid
                    }
                }
                },
                komp2: file(relativePath: { eq: "projekty/beefit/2/komp2.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                childImageSharp {
                    fluid(maxWidth: 2000, quality: 100) {
                    ...GatsbyImageSharpFluid
                    }
                }
                },

                komp3: file(relativePath: { eq: "projekty/beefit/2/komp3.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                childImageSharp {
                    fluid(maxWidth: 2000, quality: 100) {
                    ...GatsbyImageSharpFluid
                    }
                }
                },

                komp4: file(relativePath: { eq: "projekty/beefit/2/komp4.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                childImageSharp {
                    fluid(maxWidth: 2000, quality: 100) {
                    ...GatsbyImageSharpFluid
                    }
                }
                },

                komp5: file(relativePath: { eq: "projekty/beefit/2/komp5.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                childImageSharp {
                    fluid(maxWidth: 2000, quality: 100) {
                    ...GatsbyImageSharpFluid
                    }
                }
                },

                komp6: file(relativePath: { eq: "projekty/beefit/2/komp6.jpg" },
                sourceInstanceName: {
                    eq: "images"
                  }) {
                childImageSharp {
                    fluid(maxWidth: 2000, quality: 100) {
                    ...GatsbyImageSharpFluid
                    }
                }
                },
                 
            }
            `)
    
    return(
        <>

      

<Rozdzial tytul="PRINT" invert={true} tekst="Materiały drukowane były podstawą projektu Bee Fit. Naszym głównym zadaniem, oprócz stworzenia identyfikacji wizualnej marki, było opracowanie projektów etykiet oraz pudełek produktowych. Co istotne, etykiety, szczególne w obrębie produktów farmakologicznych, posiadają bardzo restrykcyjne obostrzenia względem układu, zastosowania rozwiązań typograficznych i treści wymaganych przez regulacje branżowe. Innym istotnym elementem była wielkość opakowań. Zobacz jak przełożyliśmy założenia systemowe na rozwiązania fizyczne, zapraszamy!" nr="02." img={data.okladka.childImageSharp.fluid}>
    <div><Img fluid={data.full1.childImageSharp.fluid} /></div>
    <TytulPunkty tekst="Projekty opakowań musiały rezonować  z określoną  grupą  odbiorców marki, jednocześnie przestrzegając obostrzeń technicznych wynikających z kategorii produktów marki." />
    <div><Img fluid={data.full2.childImageSharp.fluid} /></div>
    <Komp_zdjecie img1={data.komp1.childImageSharp.fluid} img2={data.komp2.childImageSharp.fluid} tytul1="Wyróżnienie linii produktowych" tekst1="Modyfikacje w skali tekstu, sposób deformacji patternów, ruch linii lotu pszczoły, oznaczenie kolorystyczne i w końcu wyeksponowana nazwa - to wszystkie elementy wyróżniające zarówno pojedyncze produkty i linie produktowe. " invert={true} />

    <Komp_zdjecie img1={data.komp3.childImageSharp.fluid} img2={data.komp4.childImageSharp.fluid} tytul1="Linia lotu pszczoły i wynikająca z niej deformacja patternu" tytul2="Paleta kolorystyczna dostosowana do kolejnych produktów" invert={true} />
    <Info tytul="Alternatywne wersje etykiet" tekst="Oprócz podstawowych projektów dla wskazanych produktów, stworzyliśmy także dodatkowe koncepcje graficzne, które mogą być zastosowane przez klienta dla nowych produktów w gamie. Szczególnie dla tych, których charakterystyka jest inna niż bieżących suplementów (np. listków)." />

    <div><Img fluid={data.full3.childImageSharp.fluid} /></div>

    <TytulPunkty tekst="Etykiety, pudełka produktowe, książeczki informujące o zastosowaniach i składzie produktów. W ramach współpracy stworzyliśmy kompleksowe rozwiązania dla marki Bee Fit." />

    <Komp_zdjecie img1={data.komp5.childImageSharp.fluid} img2={data.komp6.childImageSharp.fluid} invert={true} />

    <Info tytul="KATALOGI PRODUKTOWE" tekst="Ostatnim elementem rozwiązań drukowanych jest katalog produktów, który firma może przekazywać dystrybutorom w celu zapoznania z ofertą marki. Katalog występuje również w wersji cyfrowej." />

    <div><Img fluid={data.full4.childImageSharp.fluid} /></div>
      

        </Rozdzial>
        </>
    )
}